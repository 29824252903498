import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ContactForm from "./ContactForm"

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    height: '50vh',
  },
}));

export default function ContactPage() {
  const styles = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
	  <ContactForm />
	  <br/>
	  <br/>
	  <br/>
      </main>
    </React.Fragment>
  );
}

