import React, { useState } from "react";
import { TextField, Button, Typography, Container, Grid } from "@material-ui/core";
import axios from "axios";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://common.chicagoariranglionsclub.com/api/contact", formData);
      setStatus("Message sent successfully!");
    } catch (error) {
      setStatus("Error sending message. Please try again.");
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "50px" }}>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Contact Us
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#EBB700", color: "#fff" }}
              fullWidth
              size="large"
            >
              Send
            </Button>
          </Grid>
          {status && (
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary" align="center">
                {status}
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default ContactForm;

